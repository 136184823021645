import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import { fetchAdminAuditLogs, fetchEmployeeActivityLogs, showHideLoader, seachEmployeeLogs, resetEmployeeId } from '../../../../../src/actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';
import { accessToken, generateToken, getBaseUrl, getYear } from './../../../../actions/utility';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EmployeeSearchForm from './../genericComponent/EmployeeSearchForm';

// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Collapse,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Spinner,
    Progress,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

// import readXlsxFile from 'read-excel-file';
import EmployeeProfile from "./EmployeeProfile";
import AddEmployeeProfile from "./AddEmployeeProfile";
import AdminHeader from './../AdminHeader';
import SideMenu from './../sidMenu/SideMenu';
import { getAdminUserData } from "../../../../../src/actions/utility";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars,faHome,faUsers } from '@fortawesome/free-solid-svg-icons';
import 'react-pro-sidebar/dist/css/styles.css';

class AdminAuditLogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auditLogs: [],
            pageNo: 1,
            loadMoreEnabled: false,
            activeTab: "1",
            employeeActivityLogsDataArr: [],
            loadMoreEmpLogs: false,
            pageNo2: 1,
            searchEmpId: "",
            years: ["2019", "2020", "2021", "2022", "2023", "2024"],
            defaultYear: getYear(),
            startDate: "",
            endDate: "",
            showModal: false
        };
    }
    handleProceed = () => {
        const { startDate, endDate } = this.state;
        // Add your logic here for handling the proceed action
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
        let queryString = "start_date="+startDate+"&end_date="+endDate+'&access_token='+accessToken()
        console.log("Send to the download:"+queryString);
        const token = generateToken()
        const tokenConvert = encodeURIComponent(token)
        let url = getBaseUrl()+'api/download/logs/report?'+queryString + '&pass_token=' + tokenConvert;
        console.log("This is the base url:",url)
        window.open(url,'_blank')
        setTimeout(() => {
            this.handleClose();
        },100)
       
    };
    handleClose = () => {
        this.setState({ showModal: false })
    }
    componentWillMount() {
        // get all audit logs data
        this.props.fetchAdminAuditLogs(this.state.pageNo, "", this.state.defaultYear);
    }


    componentWillReceiveProps(nextProps, thisProps) {
        // console.log('searchEmployeeLogsData:',nextProps.searchEmployeeLogsData);
        if (this.props.getAllAuditLogs !== nextProps.getAllAuditLogs) {
            // console.log(nextProps.getAllAuditLogs)
            if (this.state.searchEmpId == "") {
                this.setState({
                    loadMoreEnabled: false,
                    auditLogs: [...this.state.auditLogs, nextProps.getAllAuditLogs.data]
                }, () => {
                    console.log(this.state.auditLogs)
                    if (this.state.pageNo == 1 && this.state.searchEmpId == "")
                        this.props.fetchEmployeeActivityLogs(this.state.pageNo, this.state.defaultYear);
                    this.props.showHideLoader(false)
                })
            }
            else {
                if (nextProps.getAllAuditLogs.data.length > 0) {
                    this.setState({
                        loadMoreEnabled: false,
                        auditLogs: []
                    }, () => {
                        console.log(this.state.auditLogs)
                        this.setState({ auditLogs: [...this.state.auditLogs, nextProps.getAllAuditLogs.data] })
                        this.props.showHideLoader(false)
                    })
                }
                else {
                    this.setState({
                        loadMoreEnabled: false
                    }, () => {
                        this.props.showHideLoader(false);
                    })
                }
            }
        }
        if (this.props.employeeActivityLogsData != nextProps.employeeActivityLogsData) {

            this.setState({
                employeeActivityLogsDataArr: [...this.state.employeeActivityLogsDataArr, nextProps.employeeActivityLogsData.data],
                loadMoreEmpLogs: false
            }, () => {
                console.log('dffdfffd:', this.state.employeeActivityLogsDataArr)
            });
        }
        if (this.props.searchEmployeeLogsData != nextProps.searchEmployeeLogsData) {
            console.log(this.state.pageNo2, ':searchEmployeeLogsData:', nextProps.searchEmployeeLogsData.data.length);
            if (nextProps.searchEmployeeLogsData.data.length && this.state.pageNo2 > 1) {
                this.setState({ employeeActivityLogsDataArr: [], loadMoreEmpLogs: false }, () => {
                    this.setState({ employeeActivityLogsDataArr: [...this.state.employeeActivityLogsDataArr, nextProps.searchEmployeeLogsData.data] })
                    this.props.showHideLoader(false);
                })
            }
            if (nextProps.searchEmployeeLogsData.data.length && this.state.pageNo2 == 1) {
                this.setState({ loadMoreEmpLogs: false, employeeActivityLogsDataArr: [] }, () => {
                    this.setState({ employeeActivityLogsDataArr: [...this.state.employeeActivityLogsDataArr, nextProps.searchEmployeeLogsData.data] })
                    this.props.showHideLoader(false);
                })
            }
            else {
                this.setState({ loadMoreEmpLogs: false }, () => {
                    console.log('loader went off')
                    this.props.showHideLoader(false);
                })
            }
        }
    }


    render() {

        const { auditLogs, loadMoreEnabled, pageNo,
            loadMoreEmpLogs, employeeActivityLogsDataArr, pageNo2, searchEmpId } = this.state;

        return (
            <div>
                {/*Side menu*/}
                <SideMenu />

                <AdminHeader title={"Audit Logs"} style={{ marginLeft: 80 }} />

                <Container className="AdminAuditLogs" style={{ padding: '5vh 0' }}>
                    <Row>
                        <Col md={{ size: 12 }}>
                            <EmployeeSearchForm tab={this.state.activeTab} searchDataFunc={(tab, empId) => {
                                console.log(empId, ":activeTab:", tab)
                                this.setState({ searchEmpId: empId }, () => {
                                    if (tab == 2)
                                        this.props.seachEmployeeLogs(empId, 1, tab);
                                    else
                                        this.props.fetchAdminAuditLogs(1, empId);
                                })
                            }} />
                        </Col>
                        <div style={{ position: "absolute", marginTop: 87, left: '75%' }}>
                            {/* <Input type="button" value="Download" onClick={() => {
                                this.setState({startDate:"",endDate:"",showModal: true })
                            }}  /> */}
                            <Button color="primary" onClick={() => {
                                this.setState({startDate:"",endDate:"",showModal: true })
                            }} > 
                            <span>Download</span>
                            </Button>

                        </div>
                        {/* <div style={{ position: "absolute", marginTop: 57, left: '88%' }}>
                            <Label for="BRANCH">Years</Label>:
                            <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                this.setState({ loadMoreEnabled: true, defaultYear: e.target.value }, () => {
                                    this.props.fetchAdminAuditLogs(this.state.pageNo, "", this.state.defaultYear);
                                })

                            }}>
                                <option value="0">Select Year</option>
                                {
                                    this.state.years.length > 0 ?
                                        this.state.years.map((year, index) =>
                                            <option value={year}>{year}</option>
                                        )
                                        : null
                                }
                            </Input>
                        </div> */}
                    </Row>
                    <Nav tabs>
                        <NavItem style={{ cursor: "pointer" }}>
                            <NavLink
                                className={this.state.activeTab == '1' ? "active" : ""}
                                onClick={() => {
                                    this.setState({ activeTab: "1" });
                                    this.props.resetEmployeeId()
                                }}
                            >
                                Admin logs
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                            <NavLink
                                className={this.state.activeTab == '2' ? "active" : ""}
                                onClick={() => {

                                    this.setState({ activeTab: "2" });
                                    this.props.resetEmployeeId()
                                }}
                            >
                                Employee logs
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col md={{ size: 8, offset: 2 }}>
                                    {
                                        auditLogs && auditLogs.length ?
                                            auditLogs.map((auditArray, key2) =>
                                                auditArray.map((audit, key) =>
                                                    <Row key={key} style={{ margin: '15px 0', border: '1px #eaeaea solid', padding: '15px', borderRadius: '5px' }}>

                                                        <Col md={{ size: 4 }} className="text-left">
                                                            Activity
                                                        </Col>
                                                        <Col md={{ size: 8 }} className="text-left">
                                                            {audit.action}
                                                        </Col>
                                                        <Col md={{ size: 4 }} className="text-left">
                                                            Admin Name
                                                        </Col>
                                                        <Col md={{ size: 8 }} className="text-left">
                                                            {audit.admin_name }
                                                        </Col>
                                                        <Col md={{ size: 4 }} className="text-left">
                                                            Employee Name
                                                        </Col>
                                                        <Col md={{ size: 8 }} className="text-left">
                                                            {audit.emp_name + ' (' + audit.employee_id + ')'}
                                                        </Col>
                                                        {
                                                            audit.desig_name != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Position
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.desig_name}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.DEPT != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Department
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.DEPT}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.branch_name != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Branch
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.branch_name}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.location_name != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Location
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.location_name}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.DIVISION != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Division
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.DIVISION}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.SECTION != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Section
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.SECTION}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.PC != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        JG
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.PC}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        <Col md={{ size: 4 }} className="text-left">
                                                            Date
                                                        </Col>
                                                        <Col md={{ size: 8 }} className="text-left">
                                                            {(new Date(audit.ts)).getDate() + '/' + (new Date(audit.ts)).getMonth() + '/' + (new Date(audit.ts)).getFullYear()}
                                                        </Col>
                                                        <Col md={{ size: 4 }} className="text-left">
                                                            Time
                                                        </Col>
                                                        <Col md={{ size: 8 }} className="text-left">
                                                            {(new Date(audit.ts)).getHours() + ':' + (new Date(audit.ts)).getMinutes() + ':' + (new Date(audit.ts)).getSeconds()}
                                                        </Col>
                                                    </Row>
                                                )
                                            )
                                            : <Spinner style={{ margin: '20vh 0' }} size="md" color="dark" />
                                    }
                                </Col>
                            </Row>

                            <Row>
                                {
                                    this.state.auditLogs.length > 0 && this.state.auditLogs.length >= 1 ?
                                        <Col>
                                            <Button style={{ textAlign: 'left' }} size="md" color="secondary"
                                                disabled={loadMoreEnabled}
                                                onClick={(e) => {
                                                    this.setState({
                                                        pageNo: pageNo + 1,
                                                        loadMoreEnabled: true
                                                    }, () => {
                                                        if (this.state.searchEmpId == "")
                                                            this.props.fetchAdminAuditLogs(pageNo + 1);
                                                        else
                                                            this.props.fetchAdminAuditLogs(pageNo + 1, this.state.searchEmpId);
                                                    })
                                                }}
                                            >
                                                {
                                                    loadMoreEnabled ?
                                                        <Spinner size="sm" color="light" style={{ marginRight: '5px' }} />
                                                        : null
                                                }
                                                Load More</Button>
                                        </Col>
                                        : null
                                }
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col md={{ size: 8, offset: 2 }}>
                                    {
                                        employeeActivityLogsDataArr && employeeActivityLogsDataArr.length ?
                                            employeeActivityLogsDataArr.map((auditArray, key2) =>
                                                auditArray.map((audit, key) =>
                                                    <Row key={key} style={{ margin: '15px 0', border: '1px #eaeaea solid', padding: '15px', borderRadius: '5px' }}>


                                                        <Col md={{ size: 4 }} className="text-left">
                                                            Name
                                                        </Col>
                                                        <Col md={{ size: 8 }} className="text-left">
                                                            {audit.employee_name + ' (' + audit.employee_id + ')'}
                                                        </Col>
                                                        {
                                                            audit.desig_name != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Position
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.desig_name}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.DEPT != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Department
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.DEPT}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.branch_name != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Branch
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.branch_name}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.location_name != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Location
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.location_name}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.DIVISION != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Division
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.DIVISION}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.SECTION != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Section
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.SECTION}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.PC != null ?
                                                                <>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        JG
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.PC}
                                                                    </Col>
                                                                </>
                                                                :

                                                                null
                                                        }
                                                        {
                                                            audit.sub_comptname != "" & audit.sub_comptname != null ?
                                                                <Fragment>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Assessment taken
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {audit.sub_comptname}
                                                                    </Col>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Assessment taken date
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {(new Date(audit.assess_taken_ts)).getDate() + '/' + (new Date(audit.assess_taken_ts)).getMonth() + '/' + (new Date(audit.assess_taken_ts)).getFullYear()} & time &nbsp;
                                                                        {(new Date(audit.assess_taken_ts)).getHours() + ':' + (new Date(audit.assess_taken_ts)).getMinutes() + ':' + (new Date(audit.assess_taken_ts)).getSeconds()}
                                                                    </Col>

                                                                </Fragment>
                                                                : null
                                                        }
                                                        {
                                                            audit.login_ts != null ?
                                                                <Fragment>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Signin date
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {(new Date(audit.login_ts)).getDate() + '/' + (new Date(audit.login_ts)).getMonth() + '/' + (new Date(audit.login_ts)).getFullYear()}  & time &nbsp;
                                                                        {(new Date(audit.login_ts)).getHours() + ':' + (new Date(audit.login_ts)).getMinutes() + ':' + (new Date(audit.login_ts)).getSeconds()}
                                                                    </Col>
                                                                </Fragment>
                                                                : null
                                                        }
                                                        {
                                                            audit.logout_ts != null ?
                                                                <Fragment>
                                                                    <Col md={{ size: 4 }} className="text-left">
                                                                        Signout date
                                                                    </Col>
                                                                    <Col md={{ size: 8 }} className="text-left">
                                                                        {(new Date(audit.logout_ts)).getDate() + '/' + (new Date(audit.logout_ts)).getMonth() + '/' + (new Date(audit.logout_ts)).getFullYear()}  & time &nbsp;
                                                                        {(new Date(audit.logout_ts)).getHours() + ':' + (new Date(audit.logout_ts)).getMinutes() + ':' + (new Date(audit.logout_ts)).getSeconds()}
                                                                    </Col>
                                                                </Fragment>
                                                                : null
                                                        }
                                                    </Row>
                                                )
                                            )
                                            : <Spinner style={{ margin: '20vh 0' }} size="md" color="dark" />
                                    }
                                </Col>
                            </Row>

                            <Row>
                                {
                                    this.state.employeeActivityLogsDataArr.length > 0 && this.state.employeeActivityLogsDataArr.length >= 1 ?
                                        <Col>
                                            <Button style={{ textAlign: 'left' }} size="md" color="secondary"
                                                disabled={loadMoreEmpLogs}
                                                onClick={(e) => {
                                                    this.setState({
                                                        pageNo2: pageNo2 + 1,
                                                        loadMoreEmpLogs: true
                                                    }, () => {
                                                        if (this.state.searchEmpId == "")
                                                            this.props.fetchEmployeeActivityLogs(pageNo2 + 1);
                                                        else
                                                            this.props.seachEmployeeLogs(this.state.searchEmpId, pageNo2 + 1);
                                                    })
                                                }}
                                            >
                                                {
                                                    loadMoreEmpLogs ?
                                                        <Spinner size="sm" color="light" style={{ marginRight: '5px' }} />
                                                        : null
                                                }
                                                Load More</Button>
                                        </Col>
                                        : null
                                }
                            </Row>
                        </TabPane>
                    </TabContent>
                    <Modal isOpen={this.state.showModal} centered size={"sm"}>
                        <ModalHeader >Select Date Range</ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <label htmlFor="startDate">Start Date:</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className="form-control"
                                    value={this.state.startDate}
                                    onChange={(e) => {
                                        this.setState({startDate:e.target.value})
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="endDate">End Date:</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    className="form-control"
                                    value={this.state.endDate}
                                    onChange={(e) => {
                                        this.setState({endDate:e.target.value})
                                    }}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={this.handleProceed}>
                                Proceed
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Container>

            </div>


        );
    }
}

AdminAuditLogs.propTypes = {
    fetchAdminAuditLogs: PropTypes.func.isRequired,
    fetchEmployeeActivityLogs: PropTypes.func.isRequired,
    showHideLoader: PropTypes.func.isRequired,
    seachEmployeeLogs: PropTypes.func.isRequired,
    resetEmployeeId: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    getAllAuditLogs: state.webRed.getAllAuditLogs,
    employeeActivityLogsData: state.webRed.employeeActivityLogsData,
    searchEmployeeLogsData: state.webRed.searchEmployeeLogsData
})

export default connect(mapStatesToProps, { fetchAdminAuditLogs, fetchEmployeeActivityLogs, showHideLoader, seachEmployeeLogs, resetEmployeeId })(AdminAuditLogs);